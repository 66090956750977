import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import LayoutForOrder from "../../components/LayoutForOrder";
import { Seo } from "../../components/seo";
import { StyledOrderH2, StyledOrderH3, StyledOrderP } from "../../styled/SText";

const OnlineShop = ({ location }) => {
  const toclist = [
    {
      title: "告知",
      url: "#kokuchi",
      items: [
        { title: "アカウント登録", url: "#account" },
        { title: "クレジットカードの登録", url: "#creditCard" },
      ],
    },
    {
      title: "購入",
      url: "#buy",
    },
    {
      title: "発送",
      url: "#shipping",
    },
  ];
  return (
    <>
      <LayoutForOrder
        mainTitle="通信販売"
        subTitle="online shop"
        panTitle="通信販売"
        panLocation={location}
        tocData={toclist}
      >
        {/* 告知 */}
        <StyledOrderH2 id={toclist[0].url.slice(1)}>告知</StyledOrderH2>
        <StyledOrderP>
          Facebook、Instagram にて上生菓子セットの販売日時を告知致します。
        </StyledOrderP>
        <StyledOrderP>
          ※事前にオンラインショップの会員登録をしていただきくと、当日の購
          入がスムーズに進みます
        </StyledOrderP>

        {/* アカウント登録 */}
        <StyledOrderH3 id={toclist[0].items[0].url.slice(1)}>
          アカウント登録
        </StyledOrderH3>
        <StyledOrderP>1 ログイン → 新規会員登録</StyledOrderP>
        <StaticImage
          src="../../images/online-shop/tuuhan1-1.png"
          className="article-img"
          alt="新規会員登録"
          layout="fullWidth"
        />
        <StyledOrderP>2 メールアドレスを入力し送信</StyledOrderP>
        <StaticImage
          src="../../images/online-shop/tuuhan1-2.png"
          className="article-img"
          alt="メールアドレスを入力し送信"
          layout="fullWidth"
        />
        <StyledOrderP>3 確認メール → 会員登録を完了する</StyledOrderP>
        <StaticImage
          src="../../images/online-shop/tuuhan1-3.png"
          className="article-img"
          alt="会員登録を完了する"
          layout="fullWidth"
        />
        <StyledOrderP>4 情報入力</StyledOrderP>
        <StaticImage
          src="../../images/online-shop/tuuhan1-4.png"
          className="article-img"
          alt="情報入力"
          layout="fullWidth"
        />
        <StyledOrderP>5 登録</StyledOrderP>

        <StaticImage
          src="../../images/online-shop/tuuhan1-5.png"
          className="article-img"
          alt="登録"
          layout="fullWidth"
        />

        {/* クレジットカードの登録 */}
        <StyledOrderH3 id={toclist[0].items[1].url.slice(1)}>
          クレジットカードの登録
        </StyledOrderH3>
        <StyledOrderP>
          事前にクレジットカードの登録をしておくと、初回購入時のクレジット
          カード情報入力を省略できます。
        </StyledOrderP>
        <StyledOrderP>1 マイページのアカウント設定をクリック</StyledOrderP>
        <StaticImage
          src="../../images/online-shop/tuuhan2-1.png"
          className="article-img"
          alt="マイページのアカウント設定をクリック"
          layout="fullWidth"
        />
        <StyledOrderP>2 クレジットカード情報をクリックし</StyledOrderP>
        <StaticImage
          src="../../images/online-shop/tuuhan2-2.png"
          className="article-img"
          alt="クレジットカード情報をクリックし"
          layout="fullWidth"
        />
        <StyledOrderP>3 クレジットカード情報を登録</StyledOrderP>
        <StaticImage
          src="../../images/online-shop/tuuhan2-3.png"
          className="article-img"
          alt="クレジットカード情報を登録"
          layout="fullWidth"
        />

        {/* 購入 */}
        <StyledOrderH2 id={toclist[1].url.slice(1)}>購入</StyledOrderH2>
        <StyledOrderP>個数を指定しカートへ</StyledOrderP>
        <StyledOrderP>
          お支払方法を選択し、時間指定のある方は時間指定
        </StyledOrderP>

        {/* 発送 */}
        <StyledOrderH2 id={toclist[2].url.slice(1)}>発送</StyledOrderH2>
        <StyledOrderP>
          クール冷凍便(ヤマト運輸)にて発送させていただきます。
        </StyledOrderP>
        <StyledOrderP>送料についてはこちら</StyledOrderP>
      </LayoutForOrder>
    </>
  );
};

export default OnlineShop;
export const Head = () => <Seo title="オンラインショップでのご注文方法"></Seo>;
